import React from 'react';
import { graphql } from 'gatsby';

import AppGoGetLayout from '../components/AppGoGetLayout';
import HeaderSeo from '../components/HeaderSeo';
import JobPageAppBar from '../views/Jobs/JobPageAppBar';
import JobPageFooter from '../views/Jobs/JobPageFooter';
import JobPageContent from '../views/Jobs/JobPageContent';
import JobDetails from '../views/Jobs/JobDetails';
import {
  generateGoogleJobPostingSchema,
  generateAgeRequirement,
  getPageTitle,
  jobTypeConversion
} from '../services/jobSite';
import { helperJobConversion } from '../services/helperJobConversion';
import MoreJobsCarousel from '../views/Jobs/MoreJobsCarousel';

const RecruitPage = props => {
  const {
    data: { hiringPost }
  } = props;

  const handleClickOpenApp = (label, referralCode) => {
    const deepLink = `${process.env.GATSBY_HOST}/gogetters/hiring_posts/${
      hiringPost?.original_id
    }${referralCode ? `%3Freferrer%3D${referralCode}` : ''}&${
      process.env.GATSBY_GOGETTER_DEEPLINK_PARAMS
    }`;
    const url = `${process.env.GATSBY_GOGETTER_APP_URL}/?link=${deepLink}`;

    window &&
      window.analytics &&
      window.analytics.track('open_app_now', {
        category: hiringPost?.helper_type || '',
        label,
        page_type: 'job_details_page',
        platform: 'static'
      });

    window.open(url, '_blank');
  };

  const pageTitle = getPageTitle(hiringPost);
  const jobType = jobTypeConversion(hiringPost?.employment_type);

  return (
    <AppGoGetLayout logoUrl="/jobs" disableAppBar>
      <HeaderSeo
        title={pageTitle}
        description={`${jobType} ${helperJobConversion(
          hiringPost?.helper_type
        )} in ${hiringPost?.city}${
          hiringPost?.state !== hiringPost?.city ? `, ${hiringPost?.state}` : ''
        }`}
        richSnippets={generateGoogleJobPostingSchema(hiringPost)}
      />
      <JobPageAppBar logoUrl="/jobs" handleClickOpenApp={handleClickOpenApp} />
      <JobPageContent handleClickOpenApp={handleClickOpenApp}>
        <JobDetails
          hiringPost={hiringPost}
          jobDescription={hiringPost?.description}
          gender={hiringPost?.gender}
          benefits={hiringPost?.benefits}
          postedAt={hiringPost?.posted_at}
          ageRequirement={generateAgeRequirement(
            hiringPost?.age_range_start,
            hiringPost?.age_range_end
          )}
          jobType={jobType}
        />
        <MoreJobsCarousel
          title="Similar jobs"
          jobId={hiringPost?.original_id}
          sliderName="HiringPostSlider"
          type="HiringPost"
        />
      </JobPageContent>
      <JobPageFooter handleClickOpenApp={handleClickOpenApp} />
    </AppGoGetLayout>
  );
};

export default RecruitPage;

export const query = graphql`
  query HiringPostQuery($pageIdJim: String) {
    hiringPost(hiring_post_id: { eq: $pageIdJim }) {
      id
      additional_requirements
      age_range_end
      age_range_start
      benefits
      city
      description
      duration_months
      employment_type
      expire_at
      gender
      helper_type
      hiring_post_id
      hours_per_week
      posted_at
      poster {
        avatar
        id
        name
        is_business_name
      }
      salary_range_end
      salary_range_start
      salary_range_timeframe
      state
      title
      original_id
    }
  }
`;
